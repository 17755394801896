import React from "react"
import clsx from "clsx"
import { makeStyles } from "@mui/styles"
import { Chip } from "@mui/material"

const useClasses = makeStyles(() => ({
	chip: {
		display: "flex",
		height: "24px",
		padding: "4px",
		justifyContent: "center",
		alignItems: "center",
		gap: "4px",
		borderRadius: "43px",
		fontSize: "14px",
		fontStyle: "normal",
		fontWeight: 500,
		color: "white",
	},
	TODO: {
		backgroundColor: "#2196F3"
	},
	LOCKED: {
		backgroundColor: "#9B9B9B"
	},
	IN_PROGRESS: {
		backgroundColor: "#F2C046"
	},
	DONE: {
		backgroundColor: "#48B468"
	},
	WEIGHT_TO_VALIDATE: {
		backgroundColor: "#1045A1"
	},
	WASTE_TO_WEIGHT: {
		backgroundColor: "#7900D9"
	},
	INSUFFICIENT_WEIGHT: {
		backgroundColor: "#F44259"
	}
}))

const PackagingExecutionStatus = ({ statusValue, statusName }) => {
	const classes = useClasses()

	return <Chip
		label={statusName}
		className={clsx(classes.chip, classes[statusValue])}
	/>
}

export default PackagingExecutionStatus