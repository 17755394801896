import Parse from "parse"

import { actionWithLoader } from "../Utils/utils"

export const generatePackagingExecutionReprodBySections = (values) => {
  return actionWithLoader(async () => {
    const packagingExecutionId = values.objectId
    const sections = values.sections.map((section) => {
      const sectionId = section.section?.id || section.section?.objectId
      const productionStepExecutionId = section.productionStepExecution?.id || section.productionStepExecution?.objectId

      const outputValues = {
        sectionId,
        productionStepExecutionId,
      }

      if (section.counterWeighing) {
        const counterWeighing = {
          weight: section.counterWeighing.weight,
          reason: section.counterWeighing.reason,
        }
        if (values.realizedNumber !== undefined) {
          counterWeighing.packagingRealizedNumber = values.realizedNumber
        }
        outputValues.counterWeighing = counterWeighing
      }
      return outputValues
    })

    const result = await Parse.Cloud.run("generatePackagingExecutionReprodBySections", {
      packagingExecutionId,
      sections,
      status: values.status,
    })

    return result
  })
}