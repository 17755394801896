export const getOrderedPackagingsForReprod = (packagings = []) => {
  const order = ["CAPPED", "REUSABLE", "DISPOSABLE"]
  return packagings.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type))
}

export const getDefaultSectionRealWeight = (section) => {
  let sectionWeight = section.recipeSectionWeight || 0

  if (section.realWeight !== undefined) {
    sectionWeight = section.realWeight
  }

  return sectionWeight
}

export const getSectionProductionWeight = (section) => {
  let sectionProductionWeight = section.initialProductionWeight // already in kilos
  if (section.counterWeighing?.weight) {
    sectionProductionWeight = section.counterWeighing.weight
  }

  return sectionProductionWeight
}

const distributePackagingNumbersForReprod = (packagings = [], initialNumber, field) => {
  // 1. Order packagings by type (CAPPED, REUSABLE, DISPOSABLE)
  const orderPackagings = getOrderedPackagingsForReprod(packagings) || []

  const newPackagings = []
  let totalPackagingNumber = 0
  let eachPackagingNumber = initialNumber

  for (const packaging of orderPackagings) {
    // 2. Distribute the initial number of packagings
    const minNumber = Math.min(eachPackagingNumber, packaging.theoreticalNumber)

    newPackagings.push({
      ...packaging,
      [field]: minNumber,
      forecastNumber: packaging.forecastNumber || 0
    })
    eachPackagingNumber -= minNumber
    totalPackagingNumber += minNumber
  }

  return {
    newPackagings,
    totalPackagingNumber
  }
}

export const getPEReprodFormInitialValues = (
  packagingExecution,
  inProgressRealizedNumber = 0,
) => {
  // realized number is only for PE in progress
  const { newPackagings, totalPackagingNumber: totalRealizedNumber } = distributePackagingNumbersForReprod(
    packagingExecution.packagings,
    inProgressRealizedNumber,
    "realizedNumber"
  )

  const cappedPackaging = newPackagings.find((packaging) => packaging.type === "CAPPED")
  const cappedPackagingWeight = cappedPackaging ? (cappedPackaging.realizedNumber - cappedPackaging.theoreticalNumber) : 0

  const realizableNumbers = []

  const sections = []

  for (const section of packagingExecution.sections) {
    const defaultRealWeight = getDefaultSectionRealWeight(section)
    const packagingForecastNumber = calculatePackagingForecastNumberBySection(section, defaultRealWeight)
    const sectionWeight = getSectionProductionWeight(section) // in kilos

    const realWeight = ((packagingExecution.expectedPackagingNumber - (inProgressRealizedNumber || 0)) * defaultRealWeight) - sectionWeight

    sections.push({
      ...section,
      realWeight: realWeight < 0 ? 0 : realWeight,
      proposedWeight: (section.counterWeight?.weight) || 0,
      forecastWaste: section.forecastWaste || 0,
      cappedPackagingWeight: cappedPackagingWeight < 0 ? 0 : cappedPackagingWeight,
      packagingForecastNumber
    })

    const realizableNumber = sectionWeight / defaultRealWeight

    realizableNumbers.push(realizableNumber)
  }

  const globalPackagingForecastNumber = calculateGlobalPackagingForecastNumber(sections)
  const updatedPackagings = calculatePackagingsForecastNumber(newPackagings, globalPackagingForecastNumber)

  const minRealizableNumber = Math.min(...realizableNumbers)
  const {
    newPackagings: updatedNewPackagings,
  } = distributePackagingNumbersForReprod(updatedPackagings, minRealizableNumber, "realizableNumber")

  return {
    ...packagingExecution,
    expectedPackagingNumber: packagingExecution.expectedPackagingNumber || 0,
    packagings: updatedNewPackagings,
    sections,
    totalRealizedNumber, // only for PE in progress
    totalRealizableNumber: minRealizableNumber, // only for PE in progress
    // won't be saved in db for display only and to ease waste calculations
    packagingForecastNumber: globalPackagingForecastNumber,
    
  }
}

export const calculatePackagingForecastNumberBySection = (section, realWeight = 1) => {
  const realWeightKilos = Number(realWeight)
  const sectionProductionWeight = getSectionProductionWeight(section) // in kilos
  if (!sectionProductionWeight) return 0

  let packagingForecastNumber = Math.floor(sectionProductionWeight / realWeightKilos)
  if (!isFinite(packagingForecastNumber)) { // if realWeight is 0 the division will return Infinity
    packagingForecastNumber = 0
  }

  return packagingForecastNumber
}

export const calculateGlobalPackagingForecastNumber = (sections = []) => {
  const sectionsWithPackagingForecastNumber = sections.filter(section => section.packagingForecastNumber && isFinite(section.packagingForecastNumber))
  const packagingForecastNumbers = sectionsWithPackagingForecastNumber.map(section => section.packagingForecastNumber)
  if (packagingForecastNumbers.length <= 0) return 0
  const minimumPackagingForecastNumber = Math.min(...packagingForecastNumbers)

  return minimumPackagingForecastNumber
}

export const calculatePackagingsForecastNumber = (packagings = [], packagingForecastNumber = 0) => {
  let currentPackagingForecastNumber = packagingForecastNumber
  const newPackagings = []

  for (const index in packagings) {
    const packaging = { ...packagings[index] }
    const isLast = Number(index) === packagings.length - 1
    const packagingTheoreticalNumber = packaging.theoreticalNumber || 0
    const usedPackagingNumber = isLast ? currentPackagingForecastNumber : Math.min(packagingTheoreticalNumber, currentPackagingForecastNumber)
    packaging.forecastNumber = usedPackagingNumber
    currentPackagingForecastNumber -= usedPackagingNumber
    currentPackagingForecastNumber = Math.max(0, currentPackagingForecastNumber)
    newPackagings.push(packaging)
  }

  return newPackagings
}

export const parseRecipeProductionStepsMigrationCSVFile = (file, callback) => {
  const reader = new FileReader()
  reader.onload = (e) => {
      const csv = e.target.result
      const allTextLines = csv.split(/\r\n|\n/)
      const headers = allTextLines[0].split(/[,;]/)
      const filteredHeaders = headers.filter(header => header.trim() !== "")
      const lines = []

      for (const line of allTextLines.slice(1)) {
          const lineArr = line.split(/[,;]/)

          // force lines to have 5 columns max
          if (lineArr.length > 5) {
            lineArr.splice(5)
          }
          if (filteredHeaders.length > 5) {
            filteredHeaders.splice(5)
          }
          
          if (lineArr.length === filteredHeaders.length) {
              const columns = []
              for (const [index,] of filteredHeaders.entries()) {
                  columns.push(lineArr[index])
              }
              lines.push(columns)
          }
      }
      callback(lines)
  }
  reader.readAsText(file)
}