import React from "react"
import { Typography, Box } from "@mui/material"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import withStyles from "@mui/styles/withStyles"

const styles = {
  container: {
    backgroundColor: "#E3F2FD",
    marginTop: 8,
    borderRadius: "8px",
    padding: 16,
    display: "flex",
    alignItems: "start",
    gap: 16,
  },
  icon: {
    width: "22px",
    height: "22px",
    color: "#0288D1",
  },
  text: {
    fontSize: 14,
    lineHeight: "22px",
    color: "#262626",
  },
}

const PackagingExecutionReprodInfo = ({ classes, text }) => {
  return (
    <Box className={classes.container}>
      <InfoIcon className={classes.icon} />
      <Typography className={classes.text}>
        {text}
      </Typography>
    </Box>
  )
}

export default withStyles(styles)(PackagingExecutionReprodInfo)