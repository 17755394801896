import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, InputAdornment, Stack, Tab, Tabs, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import UnpackingPSEsHeader from "./UnpackingPSEsHeader"
import UnpackingPSEsByIngredientsForm from "./UnpackingPSEsByIngredientsForm"
import { getIngredientsDoneSelector, getIngredientsTodoSelector } from "../../../reducers/Production/ProductionSteps"
import { changeUnpackingIngredientsStatus } from "../../../actions/ProductionSteps/ProductionStepExecutions"

const sx = {
  tabsContainer: {
    borderBottom: 1,
    borderColor: "divider",
    display: "flex",
    alignSelf: "stretch",
  },
  tabs: {
    flex: 1,
    display: "flex",
    alignSelf: "stretch",
  },
  tab: {
    textAlign: "center",
    minWidth: "50%"
  },
  search: {
    "& .MuiInputBase-root": {
      paddingLeft: "16px",
      paddingRight: "16px",
    }
  }
}

const UnpackingPSEs = () => {
  const dispatch = useDispatch()
  const initialIngredientsTodo = useSelector(getIngredientsTodoSelector)
  const initialIngredientsDone = useSelector(getIngredientsDoneSelector)
  const [tab, setTab] = useState(0)
  const [search, setSearch] = useState("")
  const [ingredientsTodo, setIngredientsTodo] = useState([])
  const [ingredientsDone, setIngredientsDone] = useState([])

  useEffect(() => {
    setIngredientsTodo(initialIngredientsTodo)
    setIngredientsDone(initialIngredientsDone)
  }, [initialIngredientsTodo, initialIngredientsDone])

  const handleChangeTab = (_, newValue) => {
    setTab(newValue)
    setSearch("")
  }

  const handleSearch = (event) => {
    const { value } = event.target
    setSearch(value)

    const filterIngredients = (ingredients) => 
      ingredients.filter((ingredient) => new RegExp(value, "i").test(ingredient.name))

    if (tab === 0) {
      setIngredientsTodo(filterIngredients(initialIngredientsTodo))
    } else {
      setIngredientsDone(filterIngredients(initialIngredientsDone))
    }
  }
  
  const handleSelectIngredients = (ingredient, currentStatus) => {
    // Done to Todo is for now not an available feature, remove this condition later if needed
    if (currentStatus === "DONE") return
    dispatch(changeUnpackingIngredientsStatus(ingredient, currentStatus))
  }

  return (
    <>
      <UnpackingPSEsHeader />
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
        <Box sx={sx.tabsContainer}>
          <Tabs
            value={tab}
            textColor="primary"
            indicatorColor="primary"
            onChange={handleChangeTab}
            sx={sx.tabs}
            aria-label="Unpacking Ingredients Tabs"
          >
            {[`Produits à DÉConditionner (${initialIngredientsTodo?.length})`, `PRODUITS DÉConditionnés (${initialIngredientsDone?.length})`].map((title, index) => (
              <Tab
                key={index}
                label={title}
                sx={sx.tab}
              />
            ))}
          </Tabs>
        </Box>
        <Stack sx={{ padding: "8px 24px" }} spacing={3}>
          {/* search input */}
          <Box>
            <TextField
              placeholder="Rechercher"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              sx={sx.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
              value={search}
            />
          </Box>
          {/* tab panels */}
          {tab === 0 && (
            <UnpackingPSEsByIngredientsForm
              ingredients={ingredientsTodo}
              onSelect={handleSelectIngredients}
              status="TODO"
            />
          )}
          {tab === 1 && (
            <UnpackingPSEsByIngredientsForm
              ingredients={ingredientsDone}
              onSelect={handleSelectIngredients}
              status="DONE"
            />
          )}
          </Stack>
        </Box>
    </>
  )
}

export default UnpackingPSEs