import React, { useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import dayjs from "dayjs"
import { Button, Stack, Box } from "@mui/material"
import PackagingExecutionHeader from "../PackagingExecutionHeader"
import PackagingExecutionFooter from "../PackagingExecutionFooter"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import PackagingExecutionResume from "./PackagingExecutionResume"
import PackagingExecutionWeightsForm from "./PackagingExecutionWeightsForm"
import PackagingExecutionComments from "./PackagingExecutionComments"
import PackagingExecutionReprodInfo from "./PackagingExecutionReprodInfo"
import { PE_STATUSES } from "../../../utils/packagingExecutions"
import { validatePackagingExecutionSectionWeights, showPackagingExecutionRead } from "../../../actions/PackagingExecution/packagingExecution"
import { packagingExecutionWeightsFormId } from "./PackagingExecutionWeightsForm"
import { getPackagingExecutionProposedWeightsSelector } from "../../../reducers/Packaging/packagingExecution"
import {COLORS} from "../../../utils"

const PackagingExecutionToValidate = ({ packagingExecution, selectedSite, selectedDate, hasSubStatus }) => {

	const packagingExecutionProposedWeightsBySections = useSelector(getPackagingExecutionProposedWeightsSelector) || {}

	const dispatch = useDispatch()

	const [hoveredCommentRow, setHoveredCommentRow] = useState(-1)

	const hasOngoingReproductions = packagingExecution.sections.some(
		section => (section.reproductions || []).some(reprod => reprod.status === "IN_PROGRESS")
	)

	const goToPackagingExecutionRead = () => {
		dispatch(showPackagingExecutionRead(selectedSite.objectId, selectedDate))
	}

	const onSubmitSectionWeights = async (values) => {
		await dispatch(validatePackagingExecutionSectionWeights(packagingExecution.objectId, values.sections, values.packagings))
		goToPackagingExecutionRead()
	}

	// see kfc-2012 disallow form submission if status is not ready or if function setPackagingExecutionsData has not yet been called for this pe
	const disableForm = useMemo(() => {
		return packagingExecution.status !== PE_STATUSES.toValidate || !packagingExecution.hasOwnProperty("expectedPackagingNumber")
	}, [packagingExecution])

	return (
		<>
			<PackagingExecutionHeader
				leftAction={<GenericHeaderReturnButton handleClick={goToPackagingExecutionRead} />}
				subtitle={`${selectedSite.name} - ${dayjs(selectedDate).format("DD/MM/YYYY")}`}
			/>
			<Box sx={{ p:3 }}>
				<Stack spacing={4}>
						<div className="flex1">
							<PackagingExecutionResume
								recipeLabel={packagingExecution.recipeName}
								status={packagingExecution.status}
								packagingLine={packagingExecution.packagingLine?.name}
								expectedPackagingNumber={packagingExecution.expectedPackagingNumber}
								uniqueCode={packagingExecution.uniqueCode}
								packagingNumbers={packagingExecution.packagingNumber || []}
								hasSubStatus={hasSubStatus}
							/>
							{hasOngoingReproductions && <PackagingExecutionReprodInfo 
								text="Une reproduction a été demandée pour atteindre la quantité prévue. Vous pouvez commencer le barquettage avec le volume disponible en validant les quantités à barquetter."/>}
						</div>
					<PackagingExecutionWeightsForm
						packagingExecution={packagingExecution}
						proposedWeightsBySections={packagingExecutionProposedWeightsBySections}
						handleSubmit={onSubmitSectionWeights}
						editable={packagingExecution.status === PE_STATUSES.toValidate}
					/>
					<Stack direction="row">
						{/** comments : feature not ready yet and not displayed */}
						<PackagingExecutionComments
							visible={false}
							editable={packagingExecution.status === PE_STATUSES.toValidate}
							comments={packagingExecution.comments}
							hoveredRow={hoveredCommentRow}
							changeHoveredRow={(index) => {
								setHoveredCommentRow(index) 
							}}
						/>
					</Stack>
				</Stack>
			</Box>
			<PackagingExecutionFooter				
				rightAction={
					<Button
						variant="contained"
						disabled={disableForm}
						type="submit"
						form={packagingExecutionWeightsFormId}
						sx={{ fontSize:"15px", lineHeight:"15px", padding:"15px 24px", backgroundColor:COLORS.PRIMARY_COLOR}}
					>
						VALIDER LES QUANTITÉS
					</Button>
				}
			/>
		</>
	)
}

export default PackagingExecutionToValidate