import dayjs from "dayjs"
import * as yup from "yup"

export const PE_STATUSES = {
  toValidate: "WEIGHT_TO_VALIDATE",
  locked: "LOCKED",
  todo: "TODO",
  inProgress: "IN_PROGRESS",
  done: "DONE",
  waste: "WASTE_TO_WEIGHT"
}

export const PE_STATUSES_AND_LABELS = [
  {
    key: "WEIGHT_TO_VALIDATE",
    label: "Poids à valider"
  },
  {
    key: "LOCKED",
    label: "En attente"
  },
  {
    key: "TODO",
    label: "À démarrer"
  },
  {
    key: "IN_PROGRESS",
    label: "En cours"
  },{
    key: "WASTE_TO_WEIGHT",
    label: "Waste réel à peser"
  },
  {
    key: "DONE",
    label: "Terminé"
  }
]


export const formatPackagingExecutionsInitialValues = (packagingExecutions) => {
  const values = {}
  values.packagingExecutions = packagingExecutions.map((packagingExecution) => ({
    id: packagingExecution.objectId,
    theoreticalStartTime: packagingExecution.theoreticalStartTime ? dayjs(packagingExecution.theoreticalStartTime).format("HH:mm") : "",
    theoreticalEndTime: packagingExecution.theoreticalEndTime ? dayjs(packagingExecution.theoreticalEndTime).format("HH:mm") : "",
    recipe: packagingExecution.recipeName,
    code: packagingExecution.uniqueCode,
    packagingLine: packagingExecution.packagingLine ? packagingExecution.packagingLine.objectId : "",
    expectedPackagingNumber: packagingExecution.expectedPackagingNumber || 0,
    status: PE_STATUSES_AND_LABELS.find(elem => elem.key === packagingExecution.status)?.label || "",
    comments: (packagingExecution.comments || []),
  }))
  return values
}

export const formatPackagingExecutionWeightsInitialValues = (packagingExecution, proposedWeightsBySections) => {
  return {
    ...packagingExecution,
    packagings: packagingExecution.packagings.map(packaging => ({
      ...packaging,
      forecastNumber: packaging.forecastNumber || 0
    })),
    sections: packagingExecution.sections.map(section => ({
      ...section,
      realWeight: 0,
      proposedWeight: proposedWeightsBySections[section.section.objectId] || 0,
      forecastWaste: section.forecastWaste || 0,
      packagingForecastNumber: Infinity, // won't be saved in db for display only and to ease waste calculations
      reproductions: section.reproductions || []
    })),
    packagingForecastNumber: 0 // won't be saved in db for display only and to ease waste calculations
  }
}

export const packagingExecutionWeightValidator = yup.object().shape({
  packagings: yup.array().of(
    yup.object().shape({
      forecastNumber: yup.number().min(0, "Le nombre de produits barquettés doit être positif")
    })
  ),
  sections: yup.array().of(
    yup.object().shape({
      realWeight: yup.number().min(0, "Le poids par barquette doit être positif"),
      forecastWaste: yup.number().min(0, "Le waste prévisionnel doit être nul ou positif")
    })
  )
})

export const getPackagingsRealNumber = (packagings) => packagings.reduce((acc, packaging) => acc += (packaging.realNumber || 0), 0)
export const getPackagingsTheoreticalNumber = (packagings) => packagings.reduce((acc, packaging) => acc += packaging.theoreticalNumber, 0)