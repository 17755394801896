import React from "react"
import { useSelector } from "react-redux"

import { getPackagingExecutionDateSelector, getPackagingExecutionSelector, getPackagingExecutionSiteSelector } from "../../reducers/Packaging/packagingExecution"
import { PE_STATUSES } from "../../utils/packagingExecutions"
import PackagingExecutionToValidate from "./packagingExecutionToValidate/PackagingExecutionToValidate"
import PackagingExecutionTodo from "./packagingExecutionTodo/PackagingExecutionTodo"
import PackagingExecutionWasteToWeight from "./packagingExecutionWasteToWeight/PackagingExecutionWasteToWeight"
import PackagingExecutionDone from "./packagingExecutionDone/PackagingExecutionDone"

const PackagingExecution = () => {
	const selectedSite = useSelector(getPackagingExecutionSiteSelector)
	const selectedDate = useSelector(getPackagingExecutionDateSelector)
	const packagingExecution = useSelector(getPackagingExecutionSelector)

	// Check if there are any sections with subStatus "INSUFFICIENT_WEIGHT"
	const hasSubStatus = packagingExecution.sections?.some(section => section.subStatus === "INSUFFICIENT_WEIGHT")

	if ([PE_STATUSES.toValidate, PE_STATUSES.locked].includes(packagingExecution.status)) {
		return <PackagingExecutionToValidate
			packagingExecution={packagingExecution}
			selectedSite={selectedSite}
			selectedDate={selectedDate}
			hasSubStatus={hasSubStatus}
		/>
	}

	if ([PE_STATUSES.todo, PE_STATUSES.inProgress].includes(packagingExecution.status)) {
		return <PackagingExecutionTodo 
			packagingExecution={packagingExecution}
			selectedSite={selectedSite}
			selectedDate={selectedDate}
			hasSubStatus={hasSubStatus}
			/>
	}

	if (PE_STATUSES.waste === packagingExecution.status) {
		return <PackagingExecutionWasteToWeight
			packagingExecution={packagingExecution}
			selectedSite={selectedSite}
			selectedDate={selectedDate}
			hasSubStatus={hasSubStatus}
		/>
	}
	if (PE_STATUSES.done === packagingExecution.status) {
		return <PackagingExecutionDone
			packagingExecution={packagingExecution}
			selectedSite={selectedSite}
			selectedDate={selectedDate}
			hasSubStatus={hasSubStatus}
		/>
	}

	return null
}

export default PackagingExecution