import React, { useState, useMemo } from "react"
import { useDispatch } from "react-redux"
import dayjs from "dayjs"
import {
	Dialog,
	styled,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button
} from "@mui/material"
import { COLORS } from "../../utils"
import PSESubstitutionSupplierItemsForm from "../ProductionStepExecutions/PSESubstitutionSupplierItemsForm"
import ScheduledSubstitutionPeriodForm from "./ScheduledSubstitutionPeriodForm"
import {
	loadProductionSubstitutionsData,
	saveFutureProductionSubstitution,
} from "../../actions/ProductionSubstitutions/ProductionSubstitutions"

export const scheduledSubstitutionStep1Id = "scheduledSubstitutionStep1"
export const scheduledSubstitutionStep2Id = "scheduledSubstitutionStep2"

const StyledDialog = styled(Dialog)({
	"& .MuiPaper-root": {
		width: 500,
		padding: 10,
	},
})

const sx = {
	title: {
		pb: 0,
		fontSize: 16,
		color: COLORS.BLACK_500,
		fontWeight: 400,
		lineHeight: 1.375,
	},
	dialogContent: {
		mt: 5,
		mb: 1,
		p: 4,
	},
	emptyDialogContent: {
		mb: 2,
		p: 0
	},
	dialogActions: {
		display: "flex",
		justifyContent: "space-between",
		pl: 4,
		pr: 4
	},
	dialogActionsValidation: {
		display: "flex",
		justifyContent: "flex-end",
		pl: 4,
		pr: 4
	}
}


const ScheduledSubstitutionDialog = ({ open, onClose, supplierItems, currentUserId }) => {
	const dispatch = useDispatch()

	const [values, setValues] = useState({})
	const [step, setStep] = useState(0)

	const title = useMemo(() => {
		if (step === 0) {
			return "Veuillez sélectionner l’article à remplacer ainsi que l’article de remplacement que vous souhaitez utiliser."
		}
		if (step === 1) {
			return "Veuillez sélectionner la période de production pendant laquelle vous souhaitez utiliser l'article de remplacement. Le remplacement se fera sur toutes les recettes contenant l’article à remplacer."
		}
		if (step === 2) {
			return `Vous avez choisi de remplacer un article dans toutes les recettes pour la période de production du ${dayjs(values.startDate).format("DD/MM/YYYY")} au ${dayjs(values.endDate).format("DD/MM/YYYY")}. Confirmez-vous cette modification ?`
		}
	}, [step, values])

	const handleSubmitFirstStep = (values) => {
		const { originalSupplierItem, replacementSupplierItem } = values
		setValues({
			originalSupplierItem: originalSupplierItem.value,
			replacementSupplierItem: replacementSupplierItem.value
		})
		setStep(1)
	}

	const handleSubmitSecondStep = (values) => {
		setValues(values)
		setStep(2)
	}

	const handleSubstitutionValidation = async () => {
		await dispatch(saveFutureProductionSubstitution({ ...values, currentUserId }))
		await dispatch(loadProductionSubstitutionsData())
		handleClose()
	}

	const handleClose = () => {
		onClose()
		setStep(0)
	}

	const isValidationStep = step === 2

	if (!open) return null

	return (
		<StyledDialog
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" sx={sx.title}>
				{title}
			</DialogTitle>
			<DialogContent sx={isValidationStep ? sx.emptyDialogContent : sx.dialogContent}>
				{step === 0 && (
					<PSESubstitutionSupplierItemsForm
						formId={scheduledSubstitutionStep1Id}
						handleSubmit={handleSubmitFirstStep}
						supplierItems={supplierItems}
					/>
				)}
				{step === 1 && (
					<ScheduledSubstitutionPeriodForm
						handleSubmit={handleSubmitSecondStep}
						formerValues={values}
						formId={scheduledSubstitutionStep2Id}
					/>
				)}
			</DialogContent>
			<DialogActions sx={isValidationStep ? sx.dialogActionsValidation : sx.dialogActions}>
				<Button onClick={handleClose} color="primary">
					Annuler
				</Button>
				{!isValidationStep && <Button
					color="primary"
					variant="contained"
					type="submit"
					form={step === 0 ? scheduledSubstitutionStep1Id : scheduledSubstitutionStep2Id}
				>
					{step === 0 ? "Suivant" : "Valider"}
				</Button>
				}
				{isValidationStep && <Button
					color="primary"
					onClick={handleSubstitutionValidation}
				>
					Confirmer
				</Button>
				}
			</DialogActions>
		</StyledDialog>
	)
}

export default ScheduledSubstitutionDialog