import Parse from "parse"
import moment from "moment"
import { parseLimitRequest } from "../../utils"

import { PSE_REGEX_HOURS, editableHourToTimestamp, sortPSEByTimeFields } from "../../utils/productionStepExecution"
import { getMachinesById } from "../machines/machinesManager"

export const ProductionStepExecution = Parse.Object.extend("ProductionStepExecutions")

export const getProductionStepExecution = async (
	id,
	toJson = true,
	includes = [
		"ulteriorStep", "priorSteps.step",
		"productionStep.stepComponents.supplierItem",
		"productionStep.kitchenArea",
		"section", "recipe", "lots",
		"productionStepSnapshot.machineType",
		"substitutes.replacementSupplierItem",
		"productionStepSnapshot.stepComponents.supplierItem",
		// for merged pses
		"mergedProductionStepExecutions"
	],
) => {
	try {
		const productionStepExecution = await new Parse.Query(ProductionStepExecution)
			.equalTo("objectId", id)
			.include(includes)
			.first()

		if (!productionStepExecution) {
			throw new Error("No productionStepExecution find")
		}

		return toJson ? productionStepExecution.toJSON() : productionStepExecution
	} catch (e) {
		return Promise.reject(e)
	}
}

export const getFilteredProductionStepExecutions = async (
	filters,
	fieldsToInclude = [],
	toJSON = true,
	withUnpackingTransformation = true,
) => {
	try {
		const basicQuery = new Parse.Query(ProductionStepExecution).notEqualTo("deleted", true).notEqualTo("isActive", false)
		if (filters.productionDay) {
			const productionDayToTimestamp = moment.utc(filters.productionDay).startOf("day").valueOf()
			basicQuery.equalTo("stepProductionDate", productionDayToTimestamp)
		}
		if (filters.states && Array.isArray(filters.states) && filters.states.length > 0) {
			basicQuery.containedIn("status", filters.states)
		}

		if (filters.kitchenArea) {
			const kitchenAreaId = filters.kitchenArea.objectId
			basicQuery.equalTo("productionStepSnapshot.kitchenArea.objectId", kitchenAreaId)
		}

		if (filters.productionStep) {
			const productionStep = await new Parse.Query(Parse.Object.extend("ProductionStep"))
				.equalTo("objectId", filters.productionStep)
				.first()

			basicQuery.equalTo("productionStep", productionStep)
		}

		if (!withUnpackingTransformation) {
			basicQuery.notEqualTo("productionStepSnapshot.transformation", "UNPACKING")
		}

		basicQuery.include(fieldsToInclude)
		const results = await basicQuery.limit(parseLimitRequest).find()
		// sort by theoreticalStartTime, startTime, endTime ascending 
		// (it's not possible to sort by multiple fields with Parse w/o a pipeline)
		const sortedResults = sortPSEByTimeFields(results)
		return toJSON ? sortedResults.map(result => result.toJSON()) : sortedResults
	}
	catch (e) {
		console.error("getFilteredProductionStepExecutions error", e)
	}
}

export const saveProductionStepsExecutionsWithHours = async (values) => {
	const ids = values.map(({ objectId }) => objectId)
	const productionStepExecutions = await new Parse.Query(ProductionStepExecution).containedIn("objectId", ids).find()

	const changedPSE = []

	for (const productionStepExecution of productionStepExecutions) {
		const productionStepExecutionValues = values.find(({ objectId }) => objectId === productionStepExecution.id)
		let changed = false

		// theoreticalStartTime
		if (PSE_REGEX_HOURS.test(productionStepExecutionValues.theoreticalStartTime)) {
			const startTimeToTimeStamp = editableHourToTimestamp(productionStepExecutionValues.theoreticalStartTime, productionStepExecution.get("stepProductionDate"))
			productionStepExecution.set("theoreticalStartTime", startTimeToTimeStamp)
			changed = true
		}
		// theoreticalEndTime
		if (PSE_REGEX_HOURS.test(productionStepExecutionValues.theoreticalEndTime)) {
			const endTimeToTimeStamp = editableHourToTimestamp(productionStepExecutionValues.theoreticalEndTime, productionStepExecution.get("stepProductionDate"))
			productionStepExecution.set("theoreticalEndTime", endTimeToTimeStamp)
			if (!changedPSE.includes(productionStepExecution)) {
				changed = true
			}
		}
		// machines
		if (productionStepExecutionValues.machines && productionStepExecutionValues.machines.length) {
			const machinesBatch = []
			for (const machine of productionStepExecutionValues.machines) {
				const machinePointer = await getMachinesById(machine, [], false)
				machinesBatch.push({ machine: machinePointer, machineName: machinePointer.get("name") })
			}
			productionStepExecution.set("machinesBatch", machinesBatch)
			changed = true
		}

		if (changed) {
			changedPSE.push(productionStepExecution)
		}
	}

	if (changedPSE.length) {
		await Parse.Object.saveAll(changedPSE)
	}
}

/**
 *  * @param sectionId
 * @param productionDate
 * @returns {Promise<*[]>}
 */
export const getAllPSEWithCommentsBySection = async (sectionId, productionDate) => {
	try {
		const section = await new Parse.Query(Parse.Object.extend("Section"))
			.equalTo("objectId", sectionId)
			.first()

		const PSEComments = await new Parse.Query(ProductionStepExecution)
			.notEqualTo("deleted", true)
			.select("comments")
			.equalTo("section", section)
			.equalTo("productionDate", productionDate)
			.exists("comments")
			.limit(parseLimitRequest)
			.find() || []

		const PSECommentsJSON = PSEComments.map(PSEComment => PSEComment.toJSON())
		const comments = []
		PSECommentsJSON.forEach(PSEComment => {
			PSEComment.comments.forEach(comment => comments.push(comment))
		})

		return comments.sort((a, b) => b.creation_dt - a.creation_dt)
	}
	catch (e) {
		console.log(e.message)
	}
}

export const getRecipesByProductionStepExecution = async (productionDate) => {
	try {
		const productionStepExecutions = await new Parse.Query(ProductionStepExecution)
			.select("recipe")
			.notEqualTo("deleted", true)
			.equalTo("productionDate", productionDate)
			.exists("recipe")
			.limit(parseLimitRequest)
			.find() || []

		const result = productionStepExecutions.map(productionStepExecution => productionStepExecution.get("recipe").id)

		return result.filter((elem, pos) => result.indexOf(elem) === pos)

	} catch (e) {
		return Promise.reject(e)
	}
}


export const loadPSEByProductionDateAndSupplierItem = async ({
	productionDate,
	supplierItemId,
	toJSON = true,
	startProductionDate,
	endProductionDate
}) => {
	const supplierItem = await new Parse.Query("SupplierItems")
		.equalTo("objectId", supplierItemId)
		.first()

	const recipeQuery = new Parse.Query("Recipe")
		.equalTo("ingredients.supplierItem", supplierItem)
		.limit(1000)

	const query = new Parse.Query("ProductionStepExecutions")
		.containedIn("status", ["TODO", "LOCKED"])
		.notEqualTo("deleted", true)
		.exists("productionStepSnapshot.stepComponents.supplierItem")

	if (productionDate) {
		query.equalTo("productionDate", productionDate)
	}

	if (startProductionDate && endProductionDate) {
		query
			.greaterThanOrEqualTo("productionDate", startProductionDate)
			.lessThanOrEqualTo("productionDate", endProductionDate)
	}
		
	const productionStepExecutions = await query
		.matchesQuery("recipe", recipeQuery)
		.findAll()
	
	const matchingProductionStepExecutions = productionStepExecutions.filter(productionStepExecution => {
		return (productionStepExecution.get("productionStepSnapshot").stepComponents || []).some(stepComponent => stepComponent.supplierItem && stepComponent.supplierItem.id === supplierItemId)
	})

	return toJSON ? matchingProductionStepExecutions.map(productionStepExecution => productionStepExecution.toJSON()) : productionStepExecutions
}
