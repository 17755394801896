import React, { useState, useEffect, useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import dayjs from "dayjs"
import { Button, Grid, Box, Stack } from "@mui/material"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import PackagingExecutionHeader from "../PackagingExecutionHeader"
import PackagingExecutionResume from "../packagingExecutionToValidate/PackagingExecutionResume"
import PackagingExecutionPresentation from "./PackagingExecutionPresentation"
import PackagingExecutionPackagingsNature from "./PackagingExecutionPackagingsNature"
import PackagingExecutionSectionsAndWeights from "./PackagingExecutionSectionsAndWeights"
import PackagingExecutionPackagingsOverview from "./PackagingExecutionPackagingsOverview"
import PackagingExecutionPackagingsNumberForm from "./PackagingExecutionPackagingsNumberForm"
import PSEPETimes from "../../ProductionStepExecutions/preview/PSEPETimes"
import PackagingExecutionReprodInfo from "../packagingExecutionToValidate/PackagingExecutionReprodInfo"
import { showPackagingExecutionRead, validatePackagingExecutionPackagingRealNumber } from "../../../actions/PackagingExecution/packagingExecution"
import { ACTIVE_KFC_BRANDS } from "../../../utils"
import { PE_STATUSES } from "../../../utils/packagingExecutions"
import { packagingNature } from "../../../utils/packaging"
import { setPackagingExecutionToInProgress } from "../../../actions/PackagingExecution/packagingExecution"
import PackagingExecutionImagePreview from "./PackagingExecutionImagePreview"

const PackagingExecutionTodo = ({ packagingExecution, selectedSite, selectedDate, hasSubStatus }) => {

	const dispatch = useDispatch()

	const goToPackagingExecutionRead = () => {
		dispatch(showPackagingExecutionRead(selectedSite.objectId, selectedDate))
	}

	const hasReproductions = packagingExecution.sections.some(
		section => Array.isArray(section.reproductions) && section.reproductions.length > 0
	)
	const hasOngoingReproductions = packagingExecution.sections.some(
		section => (section.reproductions || []).some(reprod => reprod.status === "IN_PROGRESS")
	)

	const [selectedPackaging, setSelectedPackaging] = useState(null)
	const [actionTitle, setActionTitle] = useState("Commencer le barquettage")
	const [formModalOpen, setFormModalOpen] = useState(false)
	const [imageModalOpen, setImageModalOpen] = useState(false)

	const openFormModal = () => setFormModalOpen(true)
	const closeFormModal = () => setFormModalOpen(false)

	const openImageModal = () => setImageModalOpen(true)
	const closeImageModal = () => setImageModalOpen(false)

	const savePackagingExecutionPackagingRealNumber = useCallback((values) => {
		const { realNumber } = values
		const packagingId = selectedPackaging.packaging.objectId
		const packagingExecutionId = packagingExecution.objectId
		if (packagingId && packagingExecutionId) {
			dispatch(validatePackagingExecutionPackagingRealNumber(packagingExecutionId, packagingId, realNumber))
		}
	}, [selectedPackaging, packagingExecution])

	const _savePackagingExecutionToInProgress = useCallback(() => {
		dispatch(setPackagingExecutionToInProgress(packagingExecution.objectId))
	}, [packagingExecution])

	const _savePackagingExecutionToDone = useCallback(() => {
	}, [packagingExecution, selectedPackaging])


	useEffect(() => {
		if (packagingExecution.status === PE_STATUSES.todo) {
			setActionTitle("Commencer le barquettage")
		}
		if (packagingExecution.status === PE_STATUSES.inProgress) {
			const packagings = packagingExecution.packagings || []
			const firstTodoPackaging = packagings.find(packaging => !packaging.hasOwnProperty("realNumber"))
			setSelectedPackaging(firstTodoPackaging)
			const packagingType = firstTodoPackaging?.type || ""
			const packagingTypeLabel = packagingNature.find(nature => nature.value === packagingType)?.label || ""
			setActionTitle(`Terminer le barquettage ${packagingTypeLabel}`)
		}
	}, [packagingExecution])

	const action = useMemo(() => {
		if (packagingExecution.status === PE_STATUSES.todo) {
			return _savePackagingExecutionToInProgress
		}
		if (packagingExecution.status === PE_STATUSES.inProgress && !selectedPackaging) {
			return _savePackagingExecutionToDone
		}
		return openFormModal
	}, [selectedPackaging, packagingExecution])


	return (
		<>
			<PackagingExecutionHeader
				leftAction={<GenericHeaderReturnButton handleClick={goToPackagingExecutionRead} />}
				subtitle={`${selectedSite.name} - ${dayjs(selectedDate).format("DD/MM/YYYY")}`}
				rightAction={
					<Button
						onClick={action}
						variant="contained"
					>
						{actionTitle}
					</Button>
				}
			/>
			<Box sx={{ p: 4 }}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Stack direction="row" spacing={3}>
							<div className="flex1">
								<PackagingExecutionResume
									recipeLabel={packagingExecution.recipeName}
									status={packagingExecution.status}
									packagingLine={packagingExecution.packagingLine?.name}
									expectedPackagingNumber={packagingExecution.expectedPackagingNumber}
									uniqueCode={packagingExecution.uniqueCode}
									hasSubStatus={hasSubStatus}
								/>
							</div>
							<PSEPETimes
								executionObject={packagingExecution} />
						</Stack>
						{hasReproductions && (
							<PackagingExecutionReprodInfo
								text={
									hasOngoingReproductions
										? `Une reprod a été demandée pour atteindre la quantité prévue. Avec les quantités actuelles, vous pouvez conditionner ${packagingExecution.expectedPackagingNumber || 0} barquettes.`
										: "La reprod a bien été intégrée à vos quantités."
								}
							/>
						)}
					</Grid>
					<Grid item xs={6} md={4}>
						<PackagingExecutionPresentation
							packaging={selectedPackaging || packagingExecution.packagings[0]}
							openImagePreview={openImageModal}
						/>
					</Grid>
					<Grid item xs={6} md={3}>
						<PackagingExecutionPackagingsNature
							packaging={selectedPackaging || packagingExecution.packagings[0]}
						/>
					</Grid>
					<Grid item xs={12} md={5}>
						<PackagingExecutionSectionsAndWeights
							sections={packagingExecution.sections}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<PackagingExecutionPackagingsOverview
							packagings={packagingExecution.packagings}
							selectedPackaging={selectedPackaging}
							brands={ACTIVE_KFC_BRANDS} />
					</Grid>
				</Grid>
			</Box>
			<PackagingExecutionPackagingsNumberForm
				packaging={selectedPackaging || {}}
				onClose={closeFormModal}
				open={formModalOpen}
				handleSubmit={savePackagingExecutionPackagingRealNumber}
			/>
			<PackagingExecutionImagePreview
				packaging={selectedPackaging || packagingExecution.packagings[0]}
				open={imageModalOpen}
				onClose={closeImageModal}
			/>
		</>
	)
}

export default PackagingExecutionTodo